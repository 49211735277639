/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Kad ir kaip ten bebūtų, kartais reikia nusiramint. Kartais dar reikia ir\ntreptelt kojele, kad kažkas geresnio nutiktų. Kartais kojele nesitrepsena, nes\ntipo kiti trepsėtojai tai tikriausiai geriau patrepsės, nes jie taigi su\ntrepsėtojų maikėm. Tu tai be maikės gali ir į ritmą nepataikyt."), "\n", React.createElement(_components.p, null, "Tada po kurio laiko visokiausių trepsėjimų pamatai, kad nihuja čia ne\ntrepsėtojų maikės, o tiesiog chebra savo baltas (tipo, be pareigų, žinai)\npersidažė ir viskas tipo normaliai."), "\n", React.createElement(_components.p, null, "Viskas kaip ir normaliai. Nurodymas yra nuversti nahui tiltą. Ne tavo reikalas\nkodėl. Kol mes čia kalbėsim, per tą tiltą privažiuos visokių ten ", React.createElement(_components.em, null, "visokių"), " pas\nmus. Verčiam nachujop ir viskas. Nurodymo išaiškinimą tu gausi per ", React.createElement(_components.em, null, "debrief'ą"), ".\nO dabar trotilinam gi."), "\n", React.createElement(_components.p, null, "Čiotka. Taip veikti ir turi. Problema tame, kad kartais nurodymas ateina iš\nnudažytos maikės arba iš kokio nors ", React.createElement(_components.em, null, "visokio"), ", kuris nurodymus duoda tą maikę\nnešiojančiam. Iš esmės tai kaip ir galima surakint, jei galvoji, kad kelia\ngrėsmę sau, tau ir aplinkiniams. Nu tipo kaip nuvarytus arklius. Nes nu jam tai\nvisai ", React.createElement(_components.em, null, "kū-kū"), "."), "\n", React.createElement(_components.p, null, "Bet realiai tai niekas taip gi nedaro. Nu nes funkcijos vis dar vykdosi, o\noptimalesnis sprendimas problemai kaip ir nesvarbus dabar. Turi optimalesnį –\nturi daryt viską, kad būtum išgirstas. Tik nesu tikras kada, jei po vieno\ntilto ", React.createElement(_components.em, null, "debrief'o"), " tu jau kitą tiltą trotilini. Gal tik tada, kai maikę\npersidažysi."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
